.container{
    padding:0 20px;
}

.fullHeight{
    height: 100vh;
}

.marginTop{
    margin-top:20px;
}

.logo{
    height:75px;
}

.colorWite{
    color:white;
}

.infoContainer{
    background-color:white;
    padding:20px;
    height: 100%;
}