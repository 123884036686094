:root {
  --pf-v5-global--primary-color--100: #001D44;
  --pf-v5-global--link--Color: #001D44;

  .pf-v5-c-breadcrumb {
    --pf-v5-c-breadcrumb__link--Color: rgba(0, 102, 204, 0.7);
    --pf-v5-c-breadcrumb__link--m-current--Color: rgba(110, 110, 110, 0.7);
    --pf-v5-c-breadcrumb__item-divider--Color: rgba(110, 110, 110, 0.7);
    --pf-v5-c-breadcrumb__item--FontSize: 12px;
  }
}

