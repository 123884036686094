
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#diazar-suite-grid a{
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 5px;
}
#diazar-suite-grid a:hover{
  border-color: var( --pf-v5-global--link--Color--hover );
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section[name="scrolling-section"]{
  height: 100%;
}

section[name="scrolling-section"] .scrolling-container{
  height: 100%;
}
.main-report-stack{
  height: 100%;
}

.report.report-container{
  height: 100%;
  overflow: scroll;
  background-color:rgba(0,0,0,0.35);
  border:1px solid #000;
}
.main-report-stack button.report-head-icon{
  font-size: 19px;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 0px;
}
#report-buttons{
  position: absolute;
  top: 20px;
  right: 20px;
}
#report-buttons button{
  background-color: rgba(255, 255, 255, 0.50);
}
#report-buttons button:hover{
  background-color: rgba(255, 255, 255, 1);
  transition: all .35s ease-in;
}
.report.report-container .visualizejs._jr_report_container_.jr .jrPage{
  /*
  transform: scale(1) !important;
  transform-origin: 0% 0% 0px !important;

  width: 100% !important;
*/
}

.button-zoom-options .pf-v5-c-slider{
  --pf-v5-c-slider__rail-track--Height: 0.15rem;
  --pf-v5-c-slider__thumb--before--Height: 20px;
}
.button-zoom-options .pf-v5-c-slider .pf-v5-c-slider__thumb{
  --pf-v5-c-slider__thumb-Size: 12px;
  --pf-v5-c-slider__thumb--Height: var( --pf-v5-c-slider__thumb-Size );
  --pf-v5-c-slider__thumb--Width: var( --pf-v5-c-slider__thumb-Size );
  --pf-v5-c-slider__thumb--Top: 11px;
}
.button-zoom-options .pf-v5-c-slider .pf-v5-c-slider__rail{
  --pf-v5-c-slider__rail--Padding: 10px;
  --pf-v5-c-slider__rail--PaddingTop: var(--pf-v5-c-slider__rail--Padding);
  --pf-v5-c-slider__rail--PaddingBottom: var(--pf-v5-c-slider__rail--Padding);
}
.button-zoom-options .pf-v5-c-slider button.pf-m-plain{
  --pf-v5-c-button--Padding: 2px;

  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-button--Padding);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-button--Padding);
  --pf-v5-c-button--PaddingRight: calc( 2 * var(--pf-v5-c-button--Padding) );
  --pf-v5-c-button--PaddingLeft:  calc( 2 * var(--pf-v5-c-button--Padding) );
  --pf-v5-c-button--FontSize: 0.75rem;
  --pf-v5-c-button--LineHeight: 0.75rem;
  --pf-v5-c-button--m-plain--Color: white;
}
.button-zoom-options .pf-v5-c-slider button.pf-m-plain:hover{
  --pf-v5-c-button--m-plain--BackgroundColor: rgba(0, 0, 0, 0.25);
}

.button-zoom-options button{
  --pf-v5-c-button--Padding: 2px;

  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-button--Padding);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-button--Padding);
  --pf-v5-c-button--PaddingRight: calc( 2 * var(--pf-v5-c-button--Padding) );
  --pf-v5-c-button--PaddingLeft:  calc( 2 * var(--pf-v5-c-button--Padding) );
  --pf-v5-c-button--FontSize: 0.75rem;
  --pf-v5-c-button--LineHeight: 0.75rem;
  --pf-v5-c-button--m-plain--Color: white;
}

.icon-btn.icon-btn-title{
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 12px;

  font-size: 20px;
}

.icon-btn.icon-btn-title .pf-v5-c-badge.indicator{
  vertical-align: top;
  font-size: 10px;
  padding-right: 4px;
  padding-left: 4px;
  min-width: 10px;
}

.tab-subreport{
  height: 75vh;
  border: 1px solid var(--pf-v5-global--disabled-color--200);
  border-top: none;
  padding: 10px;
}

.pf-v5-c-nav__item button.pf-v5-c-nav__link{
  text-align: left;
}

.pf-v5-l-split__item.pf-m-fill.pagination-report .pf-v5-c-options-menu{
  width: 0;
  overflow: hidden;
}

.diazar-cotizaciones #page-sidebar{
  --sidebar-botton-height: 35px;
  padding-bottom: calc( var(--sidebar-botton-height) + 5 );
  background: var(--pf-v5-c-page__sidebar--BackgroundColor) url(/public/assets/images/logo__diazar_powerby.svg) no-repeat bottom center;
  background-size: auto var( --sidebar-botton-height );
}

.diazar-cotizaciones .pf-v5-c-table thead th .pf-v5-c-button{
  font-weight: bold;
}

.diazar-cotizaciones .pf-v5-c-table thead th .pf-v5-c-button:disabled,
.diazar-cotizaciones .pf-v5-c-table thead th .pf-v5-c-button[disabled]
{
  color: #2c3038;
}

@media (max-width: 1200px ) {
  .main-report-stack{
    height: calc( 100% - 8px);
  }
}

.pf-v5-c-background-image {
  background-size: cover !important;
  /*--pf-v5-c-background-image--BackgroundPosition: center right !important;
  --pf-v5-c-background-image--BackgroundSize--max-width: 100% !important;
  --pf-v5-c-background-image--BackgroundSize--min-width: none !important;*/
}

.custom-datagrid {
  padding: 10px 10px 2.5rem 10px;
}

.custom-datagrid2 {
  padding: 10px 10px 0 10px;
}

.descuentogral {
  --pf-v5-c-form-control--Width: 25% !important;
}

.numClient {
  --pf-v5-c-form-control--Width: 40% !important;
}

.vendedor {
  --pf-v5-c-form-control--Width: 70% !important;
}

.fieldPCTDesc div {
  display: inline-block;
}

#select-clients, #select-clients-btn{
  width: 350px;
}

.dx-scrollable-container .dx-scrollable-content .dx-scrollview-content{
  min-height: 200px;
}

.quote-card {
  cursor: pointer;
}

.pf-m-clicleable h2{
  cursor: pointer;
}

.pf-m-clicleable.active h2{
  background-color: var(--base-orange);;
}

.pf-m-clicleable h2:hover{
  color: var(--pf-v5-c-nav__link--hover--Color);
  background-color: var(--pf-v5-c-nav__link--hover--BackgroundColor);
}

.info-impuestos {
  color: #1f0066;
  background-color: white;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
}

.no-editable {
  color: #A1A1A1FF;
}

.editable {
  cursor: pointer;
  font-weight: bold;
}

.vertical-center {
  display: flex;
  align-items: center;
  height: 100%;
}

